import "../../styles/SocialMediaButtons/SocialMediaButtons.css"

interface SocialMediaButtonProps {
    title: string;
    icon: 'whatsapp' | 'facebook' | 'twitter';
}

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({ title, icon }) => {
    return (
        <button type="button" className={`btn btn-${icon} text-white text-center inter fs-6 fw-bold d-flex align-items-center`}>
            <img src={`/assets/icons/${icon}.png`} alt={`${icon} CTA`} width="20%" className="me-3" />
            {title}
        </button>
    )
}

export { SocialMediaButton }