import { FC } from "react";

interface BenefitItemProps {
    benefit: 'benefit-1' | 'benefit-2' | 'benefit-3' | 'benefit-4' | 'benefit-5'
    title: string
}

const BenefitItem: FC<BenefitItemProps> = ({ benefit, title }) => {
    return (
        <>
            <img src={`/assets/icons/benefits/${benefit}.png`} alt={benefit} className="mb-4" width={72} height={72} />
            <div className="d-flex justify-content-center">
                <h5 className="desc-benefit">
                    {title}
                </h5>
            </div>
        </>
    )
}

export { BenefitItem };