import React, { FC } from 'react'
import { Post } from '../../interfaces/Post';
import { stripHTML } from '../../functions/general';
// import Mockup3 from '../../assets/mockups/mockup3.png'
import { useIntl } from "react-intl";

interface MainPostProps {
    post: Post
}

const MainPost: FC<MainPostProps> = ({ post }) => {
    const intl = useIntl()
    return (
        <div className="container-fluid px-5 w-100 w-md-75">
            <div className="card mb-5 pb-3 main-post-card">
                <div className="row g-0">
                    <div className="col-lg-6 col-md-12">
                        <img src={`/assets/posts/${post.image}`} className="main-post-image img-fluid" alt="Main Post" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card-body main-post-card-body">
                            <div className="inter text-start">
                                <p className="mini-title">
                                    <strong>{intl.formatMessage({ id: "BLOG.LATEST" })}</strong>
                                    <br />
                                    <span className="mini-date">{post.date}</span>
                                </p>
                                <h4 className="main-post-headline">
                                    <strong>{post.title}</strong>
                                </h4>
                                <p className="main-post-text">
                                    {stripHTML(post.content).length > 200 ? stripHTML(post.content).substring(0, 200) + "..." : stripHTML(post.content)}
                                </p>
                                <a href={`/articles/${post.id}`} className="text-yellow-2 main-post-link">{intl.formatMessage({ id: "BLOG.READ_MORE" })} &gt;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export { MainPost }