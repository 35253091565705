import React, { useState, useEffect, FC } from 'react'
import Profile from '../../assets/icons/user.png'
// import Mockup3 from '../../assets/mockups/mockup3.png'
import { SocialMediaButton } from '../SocialMediaButton';
import { Post } from '../../interfaces/Post';
import { usePosts } from '../../contexts/PostsProvider';
import RecommendedArticle from '../RecommendedArticle';
import { useIntl } from "react-intl";

interface PostContentProps {
    id: string
}

const PostContent: FC<PostContentProps> = ({ id }) => {
    const intl = useIntl()

    const { posts } = usePosts()
    const post = posts.find(post => post.id === parseInt(id!))

    const [offset, setOffset] = useState(-116); // Initial value

    // This will run after the initial render and every time the window is resized
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) { // Example breakpoint for mobile
                setOffset(-84.09); // Example offset for mobile
            } else {
                setOffset(-116); // Example offset for larger screens
            }
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Call handleResize right away so that the component gets the offset based on the initial window size
        handleResize();

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Ey dependency array means this effect will run once after the initial render

    return (
        <div className='article-container'>
            <div className="container pt-5 w-75 w-sm-100">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><a href="/">{intl.formatMessage({ id: "NAV.HOME" })}</a></li>
                        <li className="breadcrumb-item"><a href="/articles">{intl.formatMessage({ id: "NAV.BLOG" })}</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{post!.title}</li>
                    </ol>
                </nav>
                <div className="profile d-flex justify-content-start inter py-4">
                    <div className="profile-img">
                        <img src={Profile} alt="Profile" width={50} />
                    </div>
                    <p className="ps-3 text-start mb-0">
                        <strong><span className="title-author">BY </span>{post!.author}</strong>
                        <br />
                        <span className="title-date">{post!.date}</span>
                    </p>
                </div>
                <div className="post-content inter pt-4">
                    <h2 className='mt-4 mb-0 mx-3 mx-lg-5'>{post!.title}</h2>
                    <img src={`/assets/posts/${post!.image}`} className="img-fluid w-75 my-5 post-thumbnail" alt="Thumbnail" />
                    <div className="text-start px-3 px-lg-5 pb-4">
                        <div dangerouslySetInnerHTML={{ __html: post!.content }} />
                    </div>
                    {/* Additional content here... */}
                </div>
                {/* <div className="post-content p-5 inter">
                    <h2><strong>7 Ide Konten Instagram yang Wajib Dicoba oleh Bisnis Kamu!</strong></h2>
                    <img src={Mockup3} className="img-fluid w-75 mt-5 mb-5 post-thumbnail" alt="Thumbnail" />
                    <div className="text-start px-3 pb-5">
                        <p>
                            Di era sekarang yang serba digital ini, memiliki media sosial adalah sebuah keharusan bagi setiap pelaku bisnis. Media sosial dapat membantu pelaku bisnis untuk memasarkan produk mereka secara gratis.
                        </p>
                        <p>
                            Tidak hanya memasarkan, media sosial juga menghubungkan pelaku bisnis dengan para pelanggannya secara lebih dekat. Komunikasi yang terjalin tidak hanya antara penjual dan pelanggan akan tetapi juga dapat antara pelanggan dan pelanggan.
                        </p>
                        <div className="post-toc w-100 p-3 w-lg-40 w-md-60 my-3 lato">
                            <h6 className="text-center">Daftar Isi</h6>
                            <ol>
                                <li><Link to="chapter-1" smooth={false} offset={offset}>Apa itu Instagram?</Link></li>
                                <li><Link to="chapter-2" smooth={false} offset={offset}>Kenapa Instagram Penting?</Link></li>
                                <li><Link to="chapter-3" smooth={false} offset={offset}>7 Konten yang Wajib Dicoba</Link></li>
                                <ol>
                                    <li>Diskon</li>
                                    <li>Meme</li>
                                    <li>Quotes Inspirasi</li>
                                    <li>Reels yang sedang Trending</li>
                                    <li>Tips and Trick - Helpful Post</li>
                                    <li>Games</li>
                                    <li>Produk Terlaris</li>
                                </ol>
                            </ol>
                        </div>
                        <h4 className="py-3" id="chapter-1"><strong>Apa itu Instagram?</strong></h4>
                        <p>
                            Salah satu media sosial yang saat ini banyak digunakan oleh masyarakat adalah Instagram. Instagram merupakan platform untuk membagikan foto atau video. Instagram memiliki fitur-fitur yang menarik seperti Instagram Feeds, Instagram Story, Reels, dan terdapat banyak sekali filter yang dapat digunakan untuk memperindah foto dan video yang akan diunggah.
                        </p>
                        <h4 className="py-3" id="chapter-2"><strong>Kenapa Instagram Penting?</strong></h4>
                        <p>
                            Pengguna Instagram ini bukan hanya untuk perseorangan saja, sekarang para pelaku bisnis pun turut meramaikan Instagram dengan foto atau video produknya yang dapat menarik perhatian pelanggan.
                        </p>
                        <p>
                            Saat ini, ketika orang-orang akan membeli atau berkunjung ke suatu tempat, mereka akan mencari informasi terlebih dahulu melalui akun Instagram. Setelah mereka melihat konten yang ada pada akun tersebut dan mereka merasa itu adalah apa yang mereka cari, mereka kemungkinan besar akan menjadi seorang pelanggan untuk bisnis tersebut.
                        </p>
                        <p>
                            Oleh karena itu, konten yang ada pada Instagram bisnis ini tentu sangat penting untuk dapat memikat hati pelanggan.
                        </p>
                        <h4 className="py-3" id="chapter-3"><strong>Berikut 7 konten Instagram yang wajib dicoba oleh para pelaku bisnis :</strong></h4>
                        <ol>
                            <li className="fw-bold">Diskon</li>
                            <p>
                                Salah satu strategi marketing yang paling digemari oleh pelanggan adalah diskon. Pastikan kamu memberikan diskon di saat yang tepat, misalnya di saat hari-hari perayaan. Kamu dapat membuat konten diskon yang persuasif supaya pelanggan tertarik untuk membeli produk kamu sebelum diskonnya berakhir.
                            </p>
                            <li className="fw-bold">Meme</li>
                            <p>
                                Meme merupakan salah satu konten yang menghibur. Pastikan meme yang diposting dapat membuat audiens merasa relate sehingga konten ini mengundang audiens untuk melakukan interaksi dengan postingan kamu, misalnya seperti like, comment, atau bahkan membagikan konten, ini dapat meningkatkan brand awareness bisnis kamu juga, loh!
                            </p>
                            <li className="fw-bold">Quotes</li>
                            <p>
                                Quote atau kutipan juga bisa menjadi salah satu ide konten untuk bisnis. Buat sebuah template untuk konten quotes ini yang sesuai dengan warna atau font brand bisnis. Konten quote ini bisa berupa inspirational quote atau motivational quote.
                            </p>
                            <li className="fw-bold">Reels</li>
                            <p>
                                Saat ini Instagram memiliki fitur untuk mengunggah video singkat menggunakan lagu sebagai pengiring videonya, fitur tersebut adalah Reels. Reels ini menjadi fitur yang sangat berguna untuk meningkatkan brand awareness karena dapat dilihat oleh semua user Instagram yang bukan merupakan followers. Oleh karena itu, fitur Reels ini sangat bisa digunakan untuk mengisi konten bisnis kamu.
                            </p>
                            <li className="fw-bold">Games</li>
                            <p>
                                Bermain games adalah hal yang digemari banyak orang karena menyenangkan. Oleh karena itu, buatlah konten games yang dapat membuat audiens merasa senang dan terhibur. Games ini juga dapat diselipkan dengan hal-hal yang masih berhubungan dengan bisnis kamu. Misalnya bisnis kamu bergerak di bidang fashion, kamu bisa buat tebak-tebakan nama model pakaian, celana, atau tas yang ada pada brand bisnis kamu.
                            </p>
                            <li className="fw-bold">Produk Terlaris</li>
                            <p>
                                Produk Terlaris Setelah membuat konten-konten yang lucu dan menyenangkan, jangan lupa juga untuk tetap membuat konten mengenai produk kamu, misalnya produk terlaris. Kamu bisa jelaskan mengenai fitur, benefit, atau mungkin testimoni dari pelanggan sebelumnya mengenai produk terlaris bisnis kamu.
                            </p>
                        </ol>
                        <p>
                            Konten di instagram sangat berpengaruh pada pandangan calon pelanggan. Buatlah konten dengan sekreatif mungkin tidak hanya terbatas pada ide-ide diatas dan selalu update dengan fitur-fitur terbaru agar bisnismu makin dikenal.
                        </p>
                    </div>
                    <hr />
                    <div className="social-media-share d-flex justify-content-end mt-4 mb-5 pb-5 hide-on-small">
                        <a href="whatsapp://send?text=Check%20out%20this%20great%20article!%20[URL_OF_THE_ARTICLE]" className="text-decoration-none mx-2 hide-on-large" target="_blank" rel="noopener noreferrer">
                            <SocialMediaButton title="WhatsApp" icon="whatsapp" />
                        </a>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=www.wooblazz.com" className="text-decoration-none mx-2" target="_blank" rel="noopener noreferrer">
                            <SocialMediaButton title="Facebook" icon="facebook" />
                        </a>
                        <a href="https://twitter.com/intent/tweet?text=Check%20out%20this%20great%20article!&url=[URL_OF_THE_ARTICLE]" className="text-decoration-none mx-2" target="_blank" rel="noopener noreferrer">
                            <SocialMediaButton title="Twitter" icon="twitter" />
                        </a>
                    </div>
                    <div className="social-media-icons hide-on-large">
                        <a href="whatsapp://send?text=Check%20out%20this%20great%20article!%20[URL_OF_THE_ARTICLE]" className="text-decoration-none mx-2 btn icon-whatsapp" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/icons/whatsapp.png" height={30} alt="" />
                        </a>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=[URL_OF_THE_ARTICLE]" className="text-decoration-none mx-2 btn icon-facebook" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/icons/facebook.png" height={30} alt="" />
                        </a>
                        <a href="https://twitter.com/intent/tweet?text=Check%20out%20this%20great%20article!&url=[URL_OF_THE_ARTICLE]" className="text-decoration-none mx-2 btn icon-twitter" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/icons/twitter.png" width={30} height={30} alt="" />
                        </a>
                    </div>
                    <div className="post-navigation mt-5">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <a href="/blogs/1" className="d-flex justify-content-start text-decoration-none">
                                    <div className="d-flex align-items-center">
                                        <img src="/assets/posts/post-1.png" alt="" height={50} className="px-3" />
                                    </div>
                                    <div className="text-start">
                                        <h6>Previous Post</h6>
                                        <p><strong>Mau Penghasilan Tambahan Jutaan Rupiah?</strong></p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-sm-12 d-flex justify-content-md-end justify-content-sm-start">
                                <a href="/blogs/1" className="d-flex justify-content-start text-decoration-none">
                                    <div className="text-end">
                                        <h6>Next Post</h6>
                                        <p><strong>Mau Penghasilan Tambahan Jutaan Rupiah?</strong></p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src="/assets/posts/post-1.png" alt="" height={50} className="px-3" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <RecommendedArticle id={id} />
        </div>
    )
}

export { PostContent }