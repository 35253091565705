import HeaderLogo from "../../assets/logos/logo-header.png";

import { NavItem } from "./NavItem";
import { Dropdown } from 'rsuite';
import "../../styles/Header/Header.css"
import { useIntl } from "react-intl";
import ReactCountryFlag from "react-country-flag";

interface HeaderProps {
    activeNav: 'home' | 'blog' | 'product';
    setLocale: (locale: string) => void;
}

const Header: React.FC<HeaderProps> = ({ activeNav, setLocale }) => {

    const locale = localStorage.getItem("locale") || "id"

    const handleLanguageChange = () => {
        const locale = localStorage.getItem("locale") || "id"
        if (locale === "id") {
            setLocale("en")
            localStorage.setItem("locale", "en")
        } else {
            setLocale("id")
            localStorage.setItem("locale", "id")
        }
    }

    const intl = useIntl()

    const renderButtonLogin = (props: any, ref: any) => {
        return (
            <button {...props} ref={ref} className="btn btn-login px-4 w-sm-100">
                {intl.formatMessage({ id: "NAV.LOGIN" })}
            </button>
        );
    };

    return (
        <header className="sticky-top">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={HeaderLogo} alt="Header Logo" height={90} />
                    </a>
                    <div className="d-flex align-items-center gap-3">
                        <div className="dropdown d-block" id="dropdown-language-mobile">
                            <span className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    locale === "id" ?
                                        <ReactCountryFlag countryCode="ID" svg style={{ border: "solid 1px #000000", height: "auto" }} /> :
                                        <ReactCountryFlag countryCode="US" svg style={{ border: "solid 1px #000000", height: "auto" }} />
                                }
                            </span>
                            <ul className="dropdown-menu" style={{ right: 0, left: "auto" }}>
                                <li>
                                    <span className="dropdown-item" role="button" onClick={handleLanguageChange}>
                                        {
                                            locale === "id" ?
                                                <span>
                                                    <ReactCountryFlag countryCode="US" svg className="me-1" />
                                                    English
                                                </span> :
                                                <span>
                                                    <ReactCountryFlag countryCode="ID" svg className="me-1" />
                                                    Indonesia
                                                </span>
                                        }
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#headerNavbar"
                            aria-controls="headerNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div className="collapse navbar-collapse sora" id="headerNavbar">
                        <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0 fw-bold">
                            <NavItem title={intl.formatMessage({ id: "NAV.HOME" })} href="/" active={activeNav === "home"} />
                            <NavItem title={intl.formatMessage({ id: "NAV.PRODUCT" })} href="/#products" active={activeNav === "product"} />
                            <NavItem title={intl.formatMessage({ id: "NAV.BLOG" })} href="/articles" active={activeNav === "blog"} />
                        </ul>
                        <div className="dropdown d-none mx-4" id="dropdown-language">
                            <span className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    locale === "id" ?
                                        <ReactCountryFlag countryCode="ID" svg style={{border: "solid 1px #000000", height: "auto"}} /> :
                                        <ReactCountryFlag countryCode="US" svg style={{border: "solid 1px #000000", height: "auto"}} />
                                }
                            </span>
                            <ul className="dropdown-menu" style={{ right: 0, left: "auto" }}>
                                <li>
                                    <span className="dropdown-item" role="button" onClick={handleLanguageChange}>
                                        {
                                            locale === "id" ?
                                                <span>
                                                    <ReactCountryFlag countryCode="US" svg className="me-1" />
                                                    English
                                                </span> :
                                                <span>
                                                    <ReactCountryFlag countryCode="ID" svg className="me-1" />
                                                    Indonesia
                                                </span>
                                        }
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* <a href="https://dashboard.wooblazz.com">
                            <button className="btn btn-primary px-5 w-sm-100 w-100">{intl.formatMessage({ id: "NAV.LOGIN" })}</button>
                        </a> */}
                        <Dropdown title={intl.formatMessage({ id: "NAV.LOGIN" })} noCaret renderToggle={renderButtonLogin} placement="bottomEnd" className="w-sm-100" style={{textAlign: "right"}}>
                            <Dropdown.Item onClick={() => window.open("https://crm.wooblazz.com")}>
                                    Wooblazz CRM
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => window.open("https://dashboard.wooblazz.com")}>
                                    Wooblazz Ads
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export { Header }