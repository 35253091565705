import { useEffect, useRef, useState } from "react";
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from "react-icons/fa";

interface VideoPlayerProps {
    src: string;
    thumbnail: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, thumbnail }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isMuted, setIsMuted] = useState(true);
    const [useNativeControls, setUseNativeControls] = useState(window.innerWidth < 767);

    useEffect(() => {
        const handleResize = () => {
            setUseNativeControls(window.innerWidth < 767)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const updateProgress = () => {
        if (videoRef.current) {
            const value = (videoRef.current.currentTime / videoRef.current.duration) * 100;
            setProgress(value);
        }
    }

    const startProgressLoop = () => {
        setIsPlaying(true);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            updateProgress();
        }, 1000);
    }

    const stopProgressLoop = () => {
        setIsPlaying(false);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                startProgressLoop();
            } else {
                videoRef.current.pause();
                stopProgressLoop();
            }
        }
    }

    useEffect(() => {
        const video = videoRef.current;

        const handleVideoEnd = () => {
            setIsPlaying(false);
            setProgress(0);
            stopProgressLoop();
        };

        if (video) {
            video.addEventListener('ended', handleVideoEnd);
        }

        return () => {
            if (video) {
                video.removeEventListener('ended', handleVideoEnd);
            }
            stopProgressLoop();
        }
    }, [])

    const handleSeek = (event: any) => {
        if (videoRef.current) {
            const seekTo = (event.target.value / 100) * videoRef.current.duration;
            videoRef.current.currentTime = seekTo;
            setProgress(event.target.value)
        }
    }

    const toggleMute = () => {
        if (videoRef.current) {
            const currentVolume = videoRef.current.volume;
            if (currentVolume > 0 && !(currentVolume > 0 && videoRef.current.muted)) {
                videoRef.current.volume = 0;
                setIsMuted(true);
            } else {
                videoRef.current.volume = 1;
                setIsMuted(false);
            }
        }
    }

    const renderCustomControls = () => {
        return (
            <>
            <div className="position-relative d-flex justify-content-center align-items-center mt-2" style={{ zIndex: 1 }}>
                <button className="btn me-2" onClick={togglePlayPause}>
                    {isPlaying? <FaPause color="white"/> : <FaPlay color="white"/>}
                </button>
                <input className="form-range w-75 video-seeker" type="range" min="0" max="100" value={progress} onChange={handleSeek} />
            </div>
            </>
        )
    }

    const renderCustomMute = () => {
        return (
            <button className="btn btn-volume" onClick={toggleMute}>
                {isMuted? <FaVolumeMute color="black"/>: <FaVolumeUp color="black"/>}
            </button>
        )
    }

    useEffect(() => {
        if (videoRef.current) {
            let options = {
                rootMargin: "0px",
                threshold: [0.8, 1]
            };
        
            let handlePlay = (entries: any, observer: any) => {
                entries.forEach((entry: any) => {
                    if (entry.isIntersecting) {
                        videoRef.current?.play();
                    } else if (!entry.isIntersecting) {
                        videoRef.current?.pause();
                    }
                });
            };
        
            let observer = new IntersectionObserver(handlePlay, options);
        
            observer.observe(videoRef.current);
        }
    }, []);

    return (
        <>
            <div className="position-relative">
                <div className={`video-container w-100 mt-5 position-relative`}>
                    <video 
                        ref={videoRef}
                        controls={useNativeControls} 
                        className='product-video' 
                        src={src} 
                        poster={thumbnail} 
                        onClick={togglePlayPause}
                        onPlay={startProgressLoop}
                        onPause={stopProgressLoop}
                        // autoPlay
                        muted={isMuted}
                        loop
                    />
                </div>
                {!useNativeControls && renderCustomMute()}
            </div>
            {!useNativeControls && renderCustomControls()}
        </>
    )
}

export { VideoPlayer }