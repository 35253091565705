import React from 'react'
import { Post } from '../Posts/Post/index.tsx'
import { Post as PostInterface } from '../../interfaces/Post';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { usePosts } from '../../contexts/PostsProvider';
import { useIntl } from "react-intl";

// const posts = [
//     {
//         id: 1,
//         title: "Mau Penghasilan Tambahan Jutaan Rupiah?",
//         date: "07/07/2023",
//         content: "Kamu sedang membutuhkan penghasilan tambahan? Tidak ada salahnya untuk mengambil peluang sebagai seorang affiliate marketing. Pekerjaan yang sedang naik daun beberapa tahun terakhir. Beberapa orang menjadikan pekerjaan ini sebagai pekerjaan tetap karena jumlah yang diperoleh dapat dikatakan cukup besar.",
//         image: "post-1"
//     },
//     {
//         id: 2,
//         title: "Mau Penghasilan Tambahan Jutaan Rupiah?",
//         date: "07/07/2023",
//         content: "Kamu sedang membutuhkan penghasilan tambahan? Tidak ada salahnya untuk mengambil peluang sebagai seorang affiliate marketing. Pekerjaan yang sedang naik daun beberapa tahun terakhir. Beberapa orang menjadikan pekerjaan ini sebagai pekerjaan tetap karena jumlah yang diperoleh dapat dikatakan cukup besar.",
//         image: "post-1"
//     },
//     {
//         id: 3,
//         title: "Mau Penghasilan Tambahan Jutaan Rupiah?",
//         date: "07/07/2023",
//         content: "Kamu sedang membutuhkan penghasilan tambahan? Tidak ada salahnya untuk mengambil peluang sebagai seorang affiliate marketing. Pekerjaan yang sedang naik daun beberapa tahun terakhir. Beberapa orang menjadikan pekerjaan ini sebagai pekerjaan tetap karena jumlah yang diperoleh dapat dikatakan cukup besar.",
//         image: "post-1"
//     },
// ]

export default function RecommendedArticle({ id }) {
    const { posts } = usePosts();

    function getRandom(list) {
        return [...list].sort(() => Math.random() > 0.5 ? 1 : -1).slice(0, 3);
    }

    const randomPosts = getRandom(posts.filter(post => post.id != id));
    const intl = useIntl()
    
    return (
        <div className="container blog py-5">
            <h4 className="inter fw-bold mb-4">{intl.formatMessage({ id: "BLOG.RECOMMENDED" })}</h4>
            <Swiper
                loop={true}
                pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                    dynamicBullets: false,

                }}
                slidesPerView={3}
                spaceBetween={0}
                modules={[Pagination]}
                className="mySwiper pb-3"
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                    },
                    1024: {
                        slidesPerView: 3,
                    }
                }}
            >
                {randomPosts.map((post, index) => (
                    <SwiperSlide key={index}>
                        <div className="row mb-5">
                            <div className="col-12 d-flex justify-content-center">
                                <Post post={post} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
