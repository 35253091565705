import React from 'react'
import { useState } from 'react';
import SearchBar from '../SearchBar';
import { MainPost } from '../MainPost';
import { Post } from './Post'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Post as PostInterface } from '../../interfaces/Post';

import { usePosts } from '../../contexts/PostsProvider';

function PostList({ posts, emptyHeading }: { posts: PostInterface[], emptyHeading: string }) {
    const count = posts.length;
    let heading = emptyHeading;
    heading = count > 0 ? `` : heading;

    return (
        <div className='blog'>
            { heading && 
                <h2><strong>{heading}</strong></h2>
            }
            <Swiper
                autoHeight={true}
                loop={true}
                pagination={{
                    clickable: true,
                    renderBullet: function (index: number, className: string) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                    dynamicBullets: false,
                }}
                modules={[Pagination]}
                className="mySwiper hide-on-small hide-on-tablet"
            >
                {Array.from({ length: Math.ceil(posts.length / 6) }, (_, slideIndex) => (
                    <SwiperSlide key={slideIndex}>
                        <div className="posts hide-on-small hide-on-tablet">
                            {Array.from({ length: 2 }, (_, rowIndex) => (
                                <div className="row mb-3 pb-5" key={rowIndex}>
                                    <div className="d-flex justify-content-center"></div>
                                    {posts.slice(slideIndex * 6 + rowIndex * 3, slideIndex * 6 + (rowIndex + 1) * 3).map((post, index) => (
                                        <div className="col-4 d-flex justify-content-center" key={index}>
                                            <Post post={post} />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                loop={true}
                pagination={{
                    clickable: true,
                    renderBullet: function (index: number, className: string) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    },
                    dynamicBullets: false
                }}
                modules={[Pagination]}
                className="mySwiper mb-3 show-on-small show-on-tablet"
            >
                {posts.map((post, index) => (
                    <SwiperSlide key={index}>
                        <div className="show-on-small show-on-tablet pb-3">
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <Post post={post} />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

function filterPosts(posts: PostInterface[], searchText: string) {
    if (!searchText.trim()) {
        return posts; // Return all posts if the search text is empty
    }

    return posts.filter(post =>
        post.title.toLowerCase().includes(searchText.toLowerCase())
    );
}

const Posts = () => {

    const {posts, searchText, setSearchText} = usePosts();
    const searchedPosts = filterPosts(posts, searchText);

    return (
        <div className='article-container'>
            <div className="container my-0">
                <SearchBar
                    value={searchText}
                    onChange={(newText: string) => setSearchText(newText)}
                />
                <MainPost post={posts[posts.length - 1]} />
                <PostList
                    posts={searchedPosts}
                    emptyHeading={`Post “${searchText}” tidak ditemukan`}
                />
            </div>
        </div>
    )
}

export { Posts }