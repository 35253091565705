// import React from 'react'
import { FC, useState } from "react";
import { ProductBanner } from "./ProductBanner";
import "../../styles/Product.css"
import { useIntl } from "react-intl";

interface ProductProps {
    type: string;
}

const Product: FC<ProductProps> = ({ type }) => {
    const intl = useIntl()

    const products = [
        {
            id: 'ads',
            image: "wooblazz-ads",
            title: intl.formatMessage({ id: "PRODUCTS.ADS.HEADER" }),
            description: intl.formatMessage({ id: "PRODUCTS.ADS.DESCRIPTION" }),
            video: 'ads'
        },
        {
            id: 'meet',
            image: "wooblazz-meet",
            title: intl.formatMessage({ id: "PRODUCTS.MEET.HEADER" }),
            description: intl.formatMessage({ id: "PRODUCTS.MEET.DESCRIPTION" }),
            video: 'meet'
        },
        {
            id: 'chat',
            image: "wooblazz-chat",
            title: intl.formatMessage({ id: "PRODUCTS.CHAT.HEADER" }),
            description: intl.formatMessage({ id: "PRODUCTS.CHAT.DESCRIPTION" }),
            video: 'chat'
        },
        {
            id: 'mail',
            image: "wooblazz-mail",
            title: intl.formatMessage({ id: "PRODUCTS.MAIL.HEADER" }),
            description: intl.formatMessage({ id: "PRODUCTS.MAIL.DESCRIPTION" }),
            video: 'mail'
        }
    ]

    const feature_ads = [
        {
            id: 1,
            title: intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.HEADER1" }),
            description: intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.DESCRIPTION1" }),
        }, {
            id: 2,
            title: intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.HEADER2" }),
            description: intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.DESCRIPTION2" }),
        }, {
            id: 3,
            title: intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.HEADER3" }),
            description: intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.DESCRIPTION3" }),
        }
    ]

    const product = products.find(product => product.id === type!)

    // check window size
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

    window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth)
    })

    const [activeFeature, setActiveFeature] = useState<number>(1)

    return (
        <>
            <ProductBanner product={product!} />
            { type === 'ads' && (
                <div className={`container-fluid pb-5 mb-5 container-detail-ads px-5`}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-7 text-start mb-4 mb-lg-0 ps-lg-5 ps-0 pe-xl-5 pe-0">
                            <h3 className="sora fw-700 pb-3">{ intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.HEADER" }) }</h3>
                            <p className="sora pb-4">{ intl.formatMessage({ id: "PRODUCTS.ADS.FEATURE.DESCRIPTION" }) }</p>
                            {feature_ads.map((feature: any, index) => (
                                <div className={`feature-detail mb-3 p-4 ${feature!.id == activeFeature ? 'active' : ''}`} id={`feature-${feature!.id}`} onMouseEnter={() => setActiveFeature(feature!.id)} onClick={() => setActiveFeature(feature!.id)} key={index} >
                                    <h4 className="sora fw-600 mb-3">{feature!.title}</h4>
                                    <p className="mb-0">{feature!.description}</p>
                                </div>
                            ))}
                        </div>
                        <div className="col-10 col-md-8 col-lg-5 col-xl-4 align-content-center">
                            <img src={`/assets/screens/ads/feature-${activeFeature}.png`} className="img-fluid" />
                        </div>
                    </div>
                </div>
            )}
            { type === 'chat' && (
                <div className={`container-fluid pb-5 mb-5 container-detail-chat px-5`}>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 text-start mb-4 mb-lg-0" style={{ color: '#525252' }}>
                            <h3 className="sora fw-700 pb-3">Focus Mode</h3>
                            <p className="sora">{ intl.formatMessage({ id: "PRODUCTS.CHAT.FOCUS.DESCRIPTION1" }) }</p>
                            <p className="sora">{ intl.formatMessage({ id: "PRODUCTS.CHAT.FOCUS.DESCRIPTION2" }) }</p>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src={`/assets/screens/chat/focus.png`} className="img-fluid" />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export { Product }