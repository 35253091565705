import React, { FC, useState } from 'react'
import { Post as PostInterface } from '../../../interfaces/Post';
import { stripHTML } from '../../../functions/general';

interface ProductProps {
    id: string;
    title: string;
    description: string;
    image: string;
    video: string;
}

interface ProductBannerProps {
    product: ProductProps;
}

const ProductBanner: FC<ProductBannerProps> = ({ product }) => {
    // check window size
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

    window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth)
    })

    return (
        <div className="outer">
            <div className="inner"></div>
            <div className={`${windowSize >= 1024 && 'w-65'} container-fluid container-product-banner position-relative py-5`}>
                <img src={`/assets/icons/${product!.image}.png`} alt="" className="img-fluid img-product-banner pb-4 mb-2" />
                <h2 className='sora title-product-banner pb-4'>{product!.title}</h2>
                <h5 className='sora desc-product-banner pb-5'>{product!.description}</h5>
                <div className={`video-container w-100`}>
                    <video loop muted autoPlay controls={false} className='product-video'>
                        <source src={`/assets/videos/${product!.video}.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    )
}

export { ProductBanner }