import { useIntl } from "react-intl";
interface SlideProps {
    Slide: {
        id: string;
        headline: string;
        description: string;
    }
}


const Slide: React.FC<SlideProps> = ({ Slide }) => {
    const intl = useIntl()

    return (
        <div className="row" id="row-slide-feature">
            <div className="col-12 col-xl-6 text-start ps-5" id="slide-text-feature">
                <h2 className="fw-bold broadcast-headline" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "FEATURES.HEADER" })}}>
                    {/* <span className="text-yellow-2 arch-underline">Fitur</span> Wooblazz CRM? */}
                </h2>
                <div className="mt-5 pt-3">
                    <h2 className="fw-bold">
                        {Slide.headline}
                    </h2>
                    <p className="inter fs-4 mt-1 pe-5 broadcast-paragraph" dangerouslySetInnerHTML={{__html: Slide.description}}>
                    </p>
                </div>

            </div>
            <div className="col-12 col-xl-6" id="slide-img-feature">
                <img src={`/assets/screens/features/screen-${Slide.id}.png`} alt={Slide.headline} className="img-fluid px-3 px-xl-0" />
            </div>
        </div>
    )
}

export { Slide }