import { FC } from "react";

interface FactItemProps {
    title: string;
    description: string;
}

const FactItem: FC<FactItemProps> = ({title, description}) => {
    return (
        <div className="text-center">
            <h1 className="fw-bold text-orange-1">{title}</h1>
            <h5 className="w-85 mx-auto" dangerouslySetInnerHTML={{__html: description}}>
                {/* {description} */}
            </h5>
        </div>
    )
}

export { FactItem }