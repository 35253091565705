interface ClientsItemProps {
    // key: any;
    ClientsItem: {
        id: string;
    }
}

const ClientsItem: React.FC<ClientsItemProps> = ({ ClientsItem }) => {
    return (
        <div className="items">
            <div className="brand-list-item">
                <div className="brand-logo">
                    <img src={`/assets/clients/client_${ClientsItem.id}_1.png`}  alt="client"/>
                    <img src={`/assets/clients/client_${ClientsItem.id}_2.png`}  alt="client"/>
                </div>
            </div>
        </div>
    )
}

export { ClientsItem }