import { Dropdown } from 'rsuite';
import { useIntl } from "react-intl";
interface NavItemProps {
    title: string;
    href: string;
    active?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ title, href, active }) => {
    const intl = useIntl()
    const activeClass = active ? "active" : "";

    const renderButtonLogin = (props: any, ref: any) => {
        return (
            <button {...props} ref={ref} className={`nav-link ${activeClass}`}>
                {title}
            </button>
        );
    };

    return (
        <li className="nav-item mx-lg-4 mx-0">
            { title === intl.formatMessage({ id: "NAV.PRODUCT" }) && active ?
                <Dropdown title={intl.formatMessage({ id: "NAV.PRODUCT" })} noCaret renderToggle={renderButtonLogin} placement="bottomStart" className="w-sm-100" style={{textAlign: "right"}} trigger={['click', 'hover']}>
                    <Dropdown.Item onClick={() => window.location.href='/product/wooblazz-ads'}>
                            Wooblazz Ads
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => window.location.href='/product/wooblazz-meet'}>
                            Wooblazz Meet
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => window.location.href='/product/wooblazz-chat'}>
                            Wooblazz Chat
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => window.location.href='/product/wooblazz-mail'}>
                            Wooblazz Mail
                    </Dropdown.Item>
                </Dropdown>
                :
                <a className={`nav-link ${activeClass}`} href={href}>{title}</a>
            }
        </li>
    )
}

export { NavItem }