import { FC } from "react";
import { Posts } from '../components/Posts/index';
import { Header } from "../components/Header";
// import '../styles/Blog.css'
// import '../styles/PostDetail.css'
import { Footer } from "../components/Footer";

interface ArticlesProps {
    setLocale: (locale: string) => void;
}

const Articles: FC<ArticlesProps> = ({ setLocale }) => {
    return (
        <>
            <Header activeNav="blog" setLocale={setLocale} />
            <Posts />
            <Footer />
        </>
    )
}

export { Articles }