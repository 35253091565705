import { FC } from "react";
import { Posts } from '../components/Posts/index';
import { Header } from "../components/Header";
import '../styles/Blog.css'
import '../styles/PostDetail.css'
import { PostContent } from "../components/PostContent";
import { useParams } from 'react-router-dom';
import { Footer } from "../components/Footer";

interface ArticlesProps {
    setLocale: (locale: string) => void;
}

const ArticleContent: FC<ArticlesProps> = ({ setLocale }) => {
    const { id } = useParams<{ id: string }>()
    return (
        <>
            <Header activeNav="blog" setLocale={setLocale} />
            <PostContent id={id!} />
            <Footer />
        </>
    )
}

export { ArticleContent }