import { FC } from "react";
import "../../../styles/Home/Modules/Modules.css";
import { ModuleItem } from "./ModuleItem";
import { useIntl } from "react-intl";

const Modules: FC = () => {
    const intl = useIntl()
    
    const modules = [
        {
            image: "wooblazz-ads",
            title: intl.formatMessage({ id: "MODULE.HEADER1" }),
            description: intl.formatMessage({ id: "MODULE.DESCRIPTION1" })
        },
        {
            image: "wooblazz-meet",
            title: intl.formatMessage({ id: "MODULE.HEADER2" }),
            description: intl.formatMessage({ id: "MODULE.DESCRIPTION2" })
        },
        {
            image: "wooblazz-chat",
            title: intl.formatMessage({ id: "MODULE.HEADER3" }),
            description: intl.formatMessage({ id: "MODULE.DESCRIPTION3" })
        },
        {
            image: "wooblazz-mail",
            title: intl.formatMessage({ id: "MODULE.HEADER4" }),
            description: intl.formatMessage({ id: "MODULE.DESCRIPTION4" })
        },
    ]

    return (
        <div className="pb-5 px-md-5 mx-lg-5 bg-modules" id="products">
            <h1 className="fw-bold text-black"><span className="text-orange-1 arch-underline">4 Modul</span> Wooblazz</h1>
            <div className="d-flex justify-content-center">

                <div className="row pt-5 pb-5 w-75 w-md-100 gap-4 gap-md-0">
                    {
                        modules.map((module, index) => (
                            <ModuleItem key={index} image={module.image} title={module.title} description={module.description} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export { Modules }