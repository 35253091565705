import { FC } from "react";
import ArrowLink from "../../../assets/icons/arrow-link.png";
import { useIntl } from "react-intl";

interface ModuleItemProps {
    image: string
    title: string
    description: string
}

const ModuleItem: FC<ModuleItemProps> = ({ image, title, description }) => {
    const intl = useIntl()
    return (
        <div className="col-12 col-md-6 col-xl-3 mb-md-4 mb-xl-0">
            <div className="card rounded-5 bg-white py-4 px-2 h-100 card-module">
                <div className="card-body text-start">
                    <img src={`/assets/icons/${image}.png`} alt="" className="img-fluid" />
                    <h5 className="fw-bold mt-5 mb-4 sora">{title}</h5>
                    <p>
                        {description}
                    </p>
                </div>
                <div className="card-footer border-0 bg-white text-start pt-0 pb-3">
                    <a href={`/product/${image}`} className={`btn btn-module`}>{intl.formatMessage({ id: "MODULE.CTA" })} 
                        <img src={ArrowLink} alt="Go to Link" height={18} className="ms-1" />  
                    </a>
                </div>
            </div>
        </div>
    )
}

export { ModuleItem }