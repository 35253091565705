import { FC, useState } from "react";
import "../../../styles/Home/Advantages/Advantages.css"
import WithCRM from "../../../assets/mockups/with-crm.png"
import WithoutCRM from "../../../assets/mockups/without-crm.png"
import { AdvantagesItem } from "./AdvantagesItem";
import { useIntl } from "react-intl";

const Advantages: FC = () => {
    const intl = useIntl()

    const advantagesList = [
        intl.formatMessage({ id: "ADVANTAGES.WITH.POINT1" }),
        intl.formatMessage({ id: "ADVANTAGES.WITH.POINT2" }),
        intl.formatMessage({ id: "ADVANTAGES.WITH.POINT3" }),
        intl.formatMessage({ id: "ADVANTAGES.WITH.POINT4" })
    ]
    
    const disadvantagesList = [
        intl.formatMessage({ id: "ADVANTAGES.WITHOUT.POINT1" }),
        intl.formatMessage({ id: "ADVANTAGES.WITHOUT.POINT2" }),
        intl.formatMessage({ id: "ADVANTAGES.WITHOUT.POINT3" }),
        intl.formatMessage({ id: "ADVANTAGES.WITHOUT.POINT4" })
    ]

    const [switchActive, setSwitchActive] = useState<boolean>(true)

    // check window size
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

    window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth)
    })

    return (
        <div className="container-fluid bg-advantages sora my-5 py-5 p-xl-5">
            <div className="text-center pt-5">
                <h1 className="fw-bold">
                    { intl.formatMessage({ id: "ADVANTAGES.HEADER" }) }
                </h1>

                <h5 className="advantages-subtitle py-3">
                    { intl.formatMessage({ id: "ADVANTAGES.DESCRIPTION" }) }
                </h5>

                <div className="d-flex justify-content-center align-items-center">
                    <div className="advantages-switch bg-white rounded-pill border border-2 d-flex justify-content-evenly align-items-center w-sm-100 w-50" style={{ height: "60px" }}>
                        <h4 className={`${switchActive ? 'active' : 'inactive'} w-100 h-100 rounded-pill mb-0 py-3`} onClick={() => {
                            setSwitchActive(!switchActive)
                        }}>
                            {
                                windowSize > 576 ?
                                    intl.formatMessage({ id: "ADVANTAGES.WITH" })
                                    :
                                    intl.formatMessage({ id: "ADVANTAGES.WITH.SHORT" })
                            }
                        </h4>
                        <h4 className={`${switchActive ? 'inactive' : 'active'} mb-0 w-100 h-100 rounded-pill py-3`} onClick={() => {
                            setSwitchActive(!switchActive)
                        }}>
                            {
                                windowSize > 576 ?
                                    intl.formatMessage({ id: "ADVANTAGES.WITHOUT" })
                                    :
                                    intl.formatMessage({ id: "ADVANTAGES.WITHOUT.SHORT" })
                            }
                        </h4>
                    </div>
                </div>

                <div className="row pb-5">
                    <div className="col-12 col-lg-8">
                        <ul className="advantages-list py-5 px-3">
                            {
                                switchActive
                                    ?
                                    advantagesList.map((advantage, index) => {
                                        return (
                                            <AdvantagesItem title={advantage} key={index} />
                                        )
                                    })
                                    :
                                    disadvantagesList.map((disadvantage, index) => {
                                        return (
                                            <AdvantagesItem title={disadvantage} advantages={false} key={index} />
                                        )
                                    })
                            }
                        </ul>
                    </div>
                    <div className={`col-12 col-lg-4 ${windowSize < 1025 && 'd-flex align-items-center pb-5'}`}>
                        <img src={switchActive ? WithCRM : WithoutCRM} alt="With Wooblazz CRM" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Advantages }