import { FC } from "react";

interface AdvantagesItemProps {
    title: string
    advantages?: boolean
}

const AdvantagesItem: FC<AdvantagesItemProps> = ({ title, advantages = true }) => {
    return (
        <li className="d-flex align-items-center my-3">
            <img src={`${advantages ? '/assets/icons/check-mark.png' : '/assets/icons/x-mark.png'}`} alt="" width={48} height={48} className="p-1 me-3" />
            <h5 className="d-inline mb-0 text-start">{title}</h5>
        </li>
    )
}

export { AdvantagesItem }