import { FC } from "react";
import { FactItem } from "./FactItem";
import { useIntl } from "react-intl";
import "../../../styles/Home/Facts/Facts.css"

const Facts: FC = () => {
    const intl = useIntl()
    
    return (
        <div className="container-fluid sora my-5 px-5 container-vertical-padding bg-facts">
            <div className="text-center">
                <h1 className="fw-bold" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "FACTS.HEADER" })}}></h1>
            </div>
            <div className="row mt-5 pt-5 gap-4 gap-md-0">
                <div className="col-12 col-md-4">
                    <FactItem title="300%" description={ intl.formatMessage({ id: "FACTS.TITLE1" }) } />
                </div>
                <div className="col-12 col-md-4">
                    <FactItem title="215%" description={ intl.formatMessage({ id: "FACTS.TITLE2" }) } />
                </div>
                <div className="col-12 col-md-4">
                    <FactItem title="42%" description={ intl.formatMessage({ id: "FACTS.TITLE3" }) } />
                </div>
            </div>
        </div>
    )
}

export { Facts }