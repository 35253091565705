import "../../../styles/Home/Clients/Clients.css"

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import { Slide } from "./Slide";

import ArrowLeft from "../../../assets/icons/arrow-left-2.png"
import ArrowRight from "../../../assets/icons/arrow-right-2.png"
import { FormattedMessage, useIntl } from "react-intl";
// import "../../styles/Client.css"
import { useEffect } from "react";
import { ClientsItem } from "./ClientsItem";

const Clients: React.FC = () => {

    const intl = useIntl()

    // create array of object that only has id generate the id from 1 - 12
    const clients = []

    for(let i = 1; i <= 12; i++){
        clients.push({
            id: i.toString()
        })
    }

    useEffect(() => {
        const clients_logo = document.querySelectorAll('.brand-list');
        clients_logo.forEach((element) => {
            const items = element.querySelectorAll('.items');
            const n = items.length;

            function auto(logo: any) {
                if (logo.classList.contains('animated')) {
                    logo.classList.remove('animated');
                } else {
                    logo.classList.add('animated');
                }
            }

            const interval = setInterval(() => {
                const rand = Math.round(Math.random() * n);
                if (items[rand] && items[rand].querySelector('.brand-logo')) {
                    auto(items[rand].querySelector('.brand-logo'));
                }
            }, 1000);

            const interval2 = setInterval(() => {
                const rand = Math.round(Math.random() * n);
                if (items[rand] && items[rand].querySelector('.brand-logo')) {
                    auto(items[rand].querySelector('.brand-logo'));
                }
            }, 2000);
        });
    }, [])

    return (
        <div className="container-fluid bg-clients px-xl-5 container-vertical-padding scroll-reveal sora" id="clients">
            <h1 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "CLIENTS.HEADER" }) }} className="mb-5 pb-3 sora fw-bold">
            </h1>
            <div className="d-flex justify-content-center mb-5">
                <div className="brand-list">
                    {
                        clients.map((client, index) => (
                            <ClientsItem key={index} ClientsItem={client} />
                        ))
                    }
                </div>
            </div>
            {/* <div className="pb-5">
                <Splide
                    hasTrack={false}
                    aria-label="Broadcast Carousel"
                    options={{
                        perPage: 6,
                        perMove: 1,
                        rewind: true,
                        type: 'loop',
                        autoplay: true,
                        interval: 3000,
                        pagination: false,
                        paginationKeyboard: true,
                        arrows: false,
                        breakpoints: {
                            576: {
                                perPage: 1,
                            },
                            768: {
                                perPage: 2,
                            },
                            1200: {
                                perPage: 3,
                            }
                        },
                    }}
                    className="mt-5"
                >
                    <SplideTrack>
                        {
                            clients.map((client) => (
                                <SplideSlide key={client.id}>
                                    <Slide Slide={client} />
                                </SplideSlide>
                            ))
                        }
                    </SplideTrack>


                    <div className="splide__arrows">
                        <div className="d-flex justify-content-start">
                            <button className="splide__arrow splide__arrow--prev" id="clients-navigation-arrows">
                                <img src={ArrowLeft} alt="Clients Previous" height={29} />
                            </button>
                            <button className="splide__arrow splide__arrow--next" id="clients-navigation-arrows">
                                <img src={ArrowRight} alt="Clients Next" height={29} />
                            </button>
                        </div>
                    </div>
                </Splide>
            </div> */}
        </div>
    )
}

export { Clients }