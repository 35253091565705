import { useIntl } from "react-intl"
import "../../../styles/Home/FAQ/FAQ.css"

import { FAQItem, FAQItemType } from "./FAQItem"

const FAQ: React.FC = () => {
    const intl = useIntl()
    const FAQData: FAQItemType[] = [
        {
            id: "1",
            question: intl.formatMessage({ id: "FAQ.QUESTION1" }),
            answer: intl.formatMessage({ id: "FAQ.ANSWER1" })
        },
        {
            id: "2",
            question: intl.formatMessage({ id: "FAQ.QUESTION2" }),
            answer: intl.formatMessage({ id: "FAQ.ANSWER2" })
        },
        {
            id: "3",
            question: intl.formatMessage({ id: "FAQ.QUESTION3" }),
            answer: intl.formatMessage({ id: "FAQ.ANSWER3" })
        },
        {
            id: "4",
            question: intl.formatMessage({ id: "FAQ.QUESTION4" }),
            answer: intl.formatMessage({ id: "FAQ.ANSWER4" })
        }
    ]
    return (
        <div className="container mt-5 p-5 mb-5 pb-5 scroll-reveal" id="faq">
            <h3 className="sora fw-bold">{intl.formatMessage({ id: "FAQ.HEADER" })}</h3>
            {
                FAQData.map((item) => {
                    return (
                        <FAQItem key={item.id} FAQItem={item} />
                    )
                })
            }
        </div>
    )
}

export { FAQ }