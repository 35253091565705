import { FC } from "react";
import { Posts } from '../components/Posts/index';
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Product } from "../components/Product";

interface ProductsProps {
    setLocale: (locale: string) => void;
    type: string;
}

const Products: FC<ProductsProps> = ({ setLocale, type }) => {
    return (
        <>
            <Header activeNav="product" setLocale={setLocale} />
            <Product type={type} />
            <Footer />
        </>
    )
}

export { Products }