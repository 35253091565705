import { FC } from "react";
import "../../../styles/Home/Benefits/Benefits.css"
import { BenefitItem } from "./BenefitItem";
import { useIntl } from "react-intl";

const Benefits: FC = () => {
    const intl = useIntl()
    
    return (
        <div className="container-fluid bg-benefits px-5 container-vertical-padding sora">
            <h1 className="fw-bold" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "BENEFIT.HEADER" })}} ></h1>
            <div className="row mt-5 pb-md-5 pb-4 gap-4 gap-md-0">
                <div className="col-12 col-md-4">
                    <BenefitItem benefit="benefit-1" title={ intl.formatMessage({ id: "BENEFIT.TITLE1" }) }  />
                </div>
                <div className="col-12 col-md-4">
                    <BenefitItem benefit="benefit-2" title={ intl.formatMessage({ id: "BENEFIT.TITLE2" }) } />
                </div>
                <div className="col-12 col-md-4">
                    <BenefitItem benefit="benefit-3" title={ intl.formatMessage({ id: "BENEFIT.TITLE3" }) } />
                </div>
            </div>
            <div className="row my-md-5 mx-md-5 px-md-4 gap-4 gap-md-0">
                <div className="col-12 col-md-6">
                    <BenefitItem benefit="benefit-4" title={ intl.formatMessage({ id: "BENEFIT.TITLE4" }) } />
                </div>
                <div className="col-12 col-md-6">
                    <BenefitItem benefit="benefit-5" title={ intl.formatMessage({ id: "BENEFIT.TITLE5" }) } />
                </div>
            </div>
        </div>
    )
}

export { Benefits };