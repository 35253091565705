import { FC, useState } from "react";
import "../../../styles/Home/CTA/CTA.css"
import WhatsApp from "../../../assets/icons/whatsapp.png"
import Ellipse1 from "../../../assets/vectors/ellipse-1.png"
import Ellipse2 from "../../../assets/vectors/ellipse-2.png"
import { useIntl } from "react-intl";

const CTA: FC = () => {
    const intl = useIntl()
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

    window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth)
    })
    
    return (
        <div className="container-fluid bg-cta sora text-white container-vertical-padding position-relative z-2">
            <h1 className="fw-bold">
                { intl.formatMessage({ id: "CTA.HEADER" }) }
            </h1>
            <h5 className="mt-5">
                { intl.formatMessage({ id: "CTA.DESCRIPTION" }) }
            </h5>
            <div className="mt-5">
                <a href="https://wa.me/6282330779799" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                    <button className="btn btn-outline-light bg-white rounded-pill px-5 py-3 text-black fw-bold">
                        <div className="d-flex align-items-center">
                            <img src={WhatsApp} alt="WhatsApp" width={48} height={48} className="me-3" />
                            <span className="fs-3">
                                {/* { intl.formatMessage({ id: "CTA.BUTTON" }) } */}
                                {
                                    windowSize > 576 ?
                                        intl.formatMessage({ id: "CTA.BUTTON" })
                                        :
                                        intl.formatMessage({ id: "CTA.BUTTON.SHORT" })
                                }
                            </span>
                        </div>
                    </button>
                </a>
            </div>
            <img src={Ellipse2} alt="" className="position-absolute top-0 start-0 img-fluid z-n1" />
            <img src={Ellipse1} alt="" className="position-absolute bottom-0 end-0 img-fluid z-n1" />
        </div>
    )
}

export { CTA }