import "../../../styles/Home/Features/Features.css";

import ArrowLeft from "../../../assets/icons/arrow-left.png";
import ArrowRight from "../../../assets/icons/arrow-right.png";

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import { Slide } from "./Slide";
import { useIntl } from "react-intl";

const Features: React.FC = () => {

    const intl = useIntl()

    const SlideData = [
        {
            id: "1",
            headline: intl.formatMessage({ id: "FEATURES.HEADER1" }),
            description: intl.formatMessage({ id: "FEATURES.DESCRIPTION1" })
        },
        {
            id: "2",
            headline: intl.formatMessage({ id: "FEATURES.HEADER2" }),
            description: intl.formatMessage({ id: "FEATURES.DESCRIPTION2" })
        },
        {
            id: "3",
            headline: intl.formatMessage({ id: "FEATURES.HEADER3" }),
            description: intl.formatMessage({ id: "FEATURES.DESCRIPTION3" })
        },
        {
            id: "4",
            headline: intl.formatMessage({ id: "FEATURES.HEADER4" }),
            description: intl.formatMessage({ id: "FEATURES.DESCRIPTION4" })
        },
        {
            id: "5",
            headline: intl.formatMessage({ id: "FEATURES.HEADER5" }),
            description: intl.formatMessage({ id: "FEATURES.DESCRIPTION5" })
        },
        {
            id: "6",
            headline: intl.formatMessage({ id: "FEATURES.HEADER6" }),
            description: intl.formatMessage({ id: "FEATURES.DESCRIPTION6" })
        },
    ]
    return (
        <div
            className="container-fluid bg-features text-white p-0 p-md-3 pb-md-5 p-xl-0 px-xl-5 container-vertical-padding scroll-reveal sora"
            id="features"
        >
            <Splide
                hasTrack={false}
                aria-label="features Carousel"
                options={{
                    rewind: true,
                    autoplay: true,
                    pagination: false,
                    type: 'fade',
                    paginationKeyboard: true,
                    breakpoints: {
                        576: {
                            pagination: true,
                        }
                    }
                }}
            >
                <div className="splide__arrows d-none d-sm-block" id="features-navigation">
                    <button className="splide__arrow splide__arrow--prev" id="features-navigation-arrows">
                        <img src={ArrowLeft} alt="features Previous" height={22} />
                    </button>
                    <button className="splide__arrow splide__arrow--next" id="features-navigation-arrows">
                        <img src={ArrowRight} alt="features Previous" height={22} />
                    </button>
                </div>
                <SplideTrack>
                    {SlideData.map((slide) => (
                        <SplideSlide key={slide.id}>
                            <Slide
                                Slide={slide}
                            />
                        </SplideSlide>
                    ))}
                </SplideTrack>
            </Splide>
        </div >
    )
}

export { Features }