import { FC } from "react";

import { Header } from "../components/Header";
import { Jumbotron } from "../components/Home/Jumbotron";
import { Broadcast } from "../components/Home/Broadcast";
import { Clients } from "../components/Home/Clients";
import { FAQ } from "../components/Home/FAQ";
import { Footer } from "../components/Footer";

import { useEffect } from "react";
import anime from "animejs";
import { Benefits } from "../components/Home/Benefits";
import { Features } from "../components/Home/Features";
import { Facts } from "../components/Home/Facts";
import { Advantages } from "../components/Home/Advantages";
import { CTA } from "../components/Home/CTA";
import { Modules } from "../components/Home/Modules";

interface HomeProps {
    setLocale: (locale: string) => void;
}

const Home: FC<HomeProps> = ({ setLocale }) => {

    let showDelayedAnimated = false; // A flag to check if show-delayed has been animated
    let animatedElements = new Set();

    useEffect(() => {
        const handleScroll = () => {
            document.querySelectorAll('.scroll-reveal').forEach((element) => {
                const rect = element.getBoundingClientRect();
                if (rect.top < window.innerHeight && rect.bottom > 0 && !animatedElements.has(element.id)) {
                    animatedElements.add(element.id); // Add the element's ID to the Set

                    anime({
                        targets: element,
                        opacity: [0, 1],
                        translateY: [100, 0],
                        easing: 'easeOutExpo',
                        duration: 1000,
                        complete: () => {
                            if (!showDelayedAnimated) {
                                showDelayedAnimated = true;
                                anime({
                                    targets: '.features-show-delayed',
                                    opacity: [0, 1],
                                    delay: (el, i) => i * 250,
                                    easing: 'easeInOutSine',
                                    duration: 1000
                                });
                            }
                        }
                    });
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <Header activeNav="home" setLocale={setLocale} />
            <Jumbotron />
            <Modules />
            <Benefits />
            <Features />
            <Facts />
            <Advantages />
            <Clients />
            <CTA />
            <FAQ />
            <Footer />
        </>
    )
}

export { Home }