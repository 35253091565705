import React, { FC } from 'react'
import { Post as PostInterface } from '../../../interfaces/Post';
import { stripHTML } from '../../../functions/general';
import { useIntl } from "react-intl";

interface PostProps {
    post: PostInterface
}

const Post: FC<PostProps> = ({ post }) => {
    const intl = useIntl()
    return (
        <div className="card cards text-start inter">
            <img src={`/assets/posts/${post.image}`} className="card-img-top" alt="..." />
            <div className="card-body d-flex flex-column fixed-height-container">
                <h5 className="card-title pb-2 fw-bold">{post.title}</h5>
                <p className="post-date">{post.date}</p>
                <p className="card-text flex-grow-1 overflow-auto pb-1">
                    {stripHTML(post.content).length > 200 ? stripHTML(post.content).substring(0, 200) + "..." : stripHTML(post.content)}
                </p>
                <a href={`/articles/${post.id}`} className="btn btn-yellow w-100 d-flex align-items-center justify-content-center mt-auto">{intl.formatMessage({ id: "BLOG.READ_MORE" })}</a>
            </div>
        </div>
    )
}

export { Post }