import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import "./styles/Utilities.css";
import '@splidejs/react-splide/css';
import 'rsuite/dist/rsuite-no-reset.min.css'
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Home } from "./pages/Home";
import { IntlProvider } from "react-intl";
import { useEffect, useState } from "react";
import idLocale from "./locales/id.json";
import enLocale from "./locales/en.json";
import { Articles } from "./pages/Articles";
import { PostContent } from "./components/PostContent";
import PostsProvider from "./contexts/PostsProvider";
import { ArticleContent } from "./pages/ArticleContent";
import { Products } from "./pages/Products";

function FallbackRedirect() {
  return <Navigate to="/" replace />;
}

function App() {
  const [locale, setLocale] = useState(localStorage.getItem("locale") || "id")

  const allMessages: { [key: string]: { [key: string]: string } } = {
    id: idLocale,
    en: enLocale
  }

  const messages = allMessages[locale]

  useEffect(() => {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", "id")
    }
  }, [])

  return (
    <PostsProvider>
      <div className="App">
        <IntlProvider locale={locale} messages={messages}>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Home setLocale={setLocale} />} />
                <Route path='articles' element={<Articles setLocale={setLocale} />} />
                <Route path='articles/:id' element={<ArticleContent setLocale={setLocale} />} />
                <Route path='product/wooblazz-ads' element={<Products setLocale={setLocale} type={'ads'} />} />
                <Route path='product/wooblazz-meet' element={<Products setLocale={setLocale} type={'meet'} />} />
                <Route path='product/wooblazz-chat' element={<Products setLocale={setLocale} type={'chat'} />} />
                <Route path='product/wooblazz-mail' element={<Products setLocale={setLocale} type={'mail'} />} />
                <Route path="*" element={<FallbackRedirect />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </IntlProvider>
      </div>
    </PostsProvider>
  );
}

export default App;
