type FAQItemType = {
    id: string,
    question: string,
    answer: string
}
interface FAQItemProps {
    FAQItem: {
        id: string,
        question: string,
        answer: string
    }
}

const FAQItem: React.FC<FAQItemProps> = ({ FAQItem }) => {
    return (
        <div className="accordion my-4" id={`accordion-faq-${FAQItem.id}`}>
            <div className="accordion-item w-100 w-lg-50 w-xl-50 px-3 mx-auto">
                <h2 className="accordion-header" id={`"heading-${FAQItem.id}"`}>
                    <button className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#faq-${FAQItem.id}`} aria-expanded="false" aria-controls={`faq-${FAQItem.id}`}>
                        {FAQItem.question}
                    </button>
                </h2>
                <div id={`faq-${FAQItem.id}`} className="accordion-collapse collapse" aria-labelledby={`"heading-${FAQItem.id}"`} data-bs-parent={`#accordion-faq-${FAQItem.id}`}>
                    <div className="accordion-body mt-2 text-start faq-answer-font" dangerouslySetInnerHTML={{__html: FAQItem.answer}}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { FAQItem, type FAQItemType }