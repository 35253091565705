import "../../../styles/Home/Jumbotron/Jumbotron.css";
import anime from "animejs";
import { useEffect, useState } from "react";
import JumbotronImage from "../../../assets/mockups/jumbotron.png"

import { FormattedMessage, useIntl } from "react-intl";

import VectorStart from "../../../assets/vectors/vector-start.png"
import VectorEnd from "../../../assets/vectors/vector-end.png"
import Box from "../../../assets/misc/box.png"
import CheckerBoard from "../../../assets/mockups/checkerboard.png"
import { VideoPlayer } from "../../VideoPlayer";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Jumbotron: React.FC = () => {
    useEffect(() => {
        anime.timeline({ loop: false })
            .add({
                targets: '#content',
                opacity: [0, 1],
                easing: 'easeInOutQuart',
                duration: 1000,
                offset: '-=500'
            })

        anime({
            targets: '.numbers-delayed',
            opacity: [0, 1], // From 0 to 1 to fade in
            delay: (el, i) => i * 500, // Delay based on index, so each element is delayed more
            easing: 'easeInOutSine',
            duration: 1000
        })
    }, []);

    const intl = useIntl()

    // check window size
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

    window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth)
    })

    useEffect(() => {
        AOS.init();
      }, [])
      
    return (
        <div className="container-fluid bg-jumbotron sora position-relative z-2 pb-5" style={{backgroundImage: `url(${CheckerBoard})`}}>
            <div className="d-flex justify-content-center">
                <div className={`${windowSize >= 1024 && 'w-65'} container-top-padding`}>
                    <div className="fw-bold mx-auto title-jumbotron" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">CRM Solution for</div>
                    <div className="fw-bold mx-auto title-jumbotron" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">Sales <span className="title-gradient">Performance</span></div>
                    {/* <div className="my-3 d-flex justify-content-center">
                        <h1
                            className="fw-bold border border-2 border-white text-black px-3 py-2 mt-3 position-relative"
                            style={{ backgroundColor: "#FFE660" }}>
                            #Wooblazz CRM
                            <img src={Box} alt="" className="position-absolute top-0 start-0 translate-middle" />
                            <img src={Box} alt="" className="position-absolute top-0 start-100 translate-middle" />
                            <img src={Box} alt="" className="position-absolute top-100 start-0 translate-middle" />
                            <img src={Box} alt="" className="position-absolute top-100 start-100 translate-middle" />
                        </h1>
                    </div> */}
                    <div className="d-flex justify-content-center">
                        <h4 className="mt-3 jumbotron-description" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-anchor-placement="top-bottom">
                            {intl.formatMessage({ id: "JUMBOTRON.CONTENT" })}
                        </h4>
                    </div>
                    <div className="d-flex justify-content-center mt-4 flex-wrap gap-2" data-aos="fade-up" data-aos-duration="500" data-aos-delay="1500" data-aos-anchor-placement="top-bottom">
                        <a href="/#products" className={`btn btn-jumbotron-orange px-4 py-2 mx-1`}>{intl.formatMessage({ id: "JUMBOTRON.CTA1" })}</a>
                        <a href="https://wa.me/6282330779799" target="_blank" rel="noopener noreferrer" className={`btn btn-jumbotron-white px-4 py-2 mx-1`}>{intl.formatMessage({ id: "JUMBOTRON.CTA2" })}</a>
                    </div>
                    <VideoPlayer src={`/assets/videos/home.mp4`} thumbnail={`/assets/videos/thumbnail.png`} />
                </div>
            </div>
            <div className="ellips-bottom"></div>
            {/* <img src={JumbotronImage} alt="Wooblazz CRM" className="mt-5 pt-4 jumbotron-image rounded-4" width={"68%"} /> */}
            {/* <img src={VectorStart} alt="" className="img-fluid position-absolute bottom-0 start-0 z-n1" /> */}
            {/* <img src={VectorEnd} alt="" className="img-fluid position-absolute bottom-0 end-0 z-n1" /> */}
        </div>
    )
}

export { Jumbotron }    