import React from 'react'
import Search from '../../assets/icons/search.png'
import { useIntl } from "react-intl";

export default function SearchBar({ value, onChange}) {
    const intl = useIntl()
    const handleInputChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className="container p-5 w-100 w-md-50">
            <div className="input-group">
                <input type="text" className="form-control roboto search-bar px-4" placeholder={intl.formatMessage({ id: "BLOG.SEARCH" })} aria-label="Cari Artikel" aria-describedby="basic-addon1" value={value}
                onChange={handleInputChange}/>
                <span className="input-group-text search-icon" id="basic-addon1">
                    <img src={Search} alt="" height={25} />
                </span>
            </div>
        </div>
    )
}
