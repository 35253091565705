import { FC } from "react";

import "../../styles/Footer/Footer.css"

import { SocialMediaButton } from "../SocialMediaButton";
import Instagram from "../../assets/icons/instagram.png"
import FooterLogo from "../../assets/logos/logo-header.png"
import { useIntl } from "react-intl";

const Footer: FC = () => {
    const intl = useIntl()
    return (
        <footer className="bg-footer p-5 inter text-white text-start">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-4 d-flex flex-column justify-content-evenly align-items-center">
                        <div>
                            <img src={FooterLogo} alt="Footer Logo" className="img-fluid" style={{ maxHeight: '137px' }} />
                        </div>
                        <h4 className="fw-bold">
                            PT. Tunas Kreasi Digital
                        </h4>
                    </div>
                    <div className="col-12 col-sm-4 d-flex flex-column justify-content-center align-items-center">
                        <div>
                            <h5 className="fw-bold">Head Office</h5>
                            <p>
                                Voza Office Tower 16th Floor
                                <br />
                                Jl. HR Muhammad 31A, Surabaya
                            </p>
                        </div>
                        <div style={{minWidth: "242.78px"}}>
                            <h5 className="fw-bold">Branch</h5>
                            <p>
                                Ruko Arcadia Grande D-9
                                <br />
                                Gading Serpong, Tangerang
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 d-flex justify-content-evenly align-items-center mb-3">
                        <div>
                            <p>{intl.formatMessage({ id: "FOOTER.CONTACT" })}</p>
                            <div>
                                <a href="https://wa.me/6282330779799" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                    <SocialMediaButton title="WhatsApp" icon="whatsapp" />
                                </a>
                            </div>
                            <div className="mt-3">
                                <a href="https://instagram.com/wooblazz" className="link-underline-light" target="_blank" rel="noreferrer">
                                    <img src={Instagram} alt="Instagram" />
                                    <span className="ms-2 text-white">
                                        Wooblazz
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 d-flex justify-content-center">
                        <p className="p-copyright">Copyright ©{new Date().getFullYear()} All rights reserved | Wooblazz</p>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export { Footer }